const staticItems = {
  Environments: {
    DEV: "DEV",
    TEST: "TEST",
    UAT: "UAT",
    PROD: "PROD"
  },
  Client: `${process.env.NEXT_PUBLIC_CLIENT}`,
  Product: "SFEMHO5FL",
  AllowedStates: ["FL"],
  MasterData: [],
  LOBList: {
    HO5: `'${process.env.NEXT_PUBLIC_LINE_OF_BUSINESS}'`
  },
  RiskAttributes: [
    {
      RiskType: "Name",
      RiskValue: 123123
    }
  ],
  MastersByContainer: [
    "ProgressMilestones",
    "StaticAgenciesUWAgent",
    "Integrations",
    "agencies",
    "agents",
    "underwriter",
    "FeesAndTaxes",
    "PC",
    "Bceg",
    "MasterCancellationReason",
    "ClosedCBG"
  ],
  DefaultCoverage: [
    {
      Occupancy: "Primary",
      Coverages: [
        { Name: "coverageB", value: "10", type: "P", percentof: "coverageA" },
        { Name: "coverageC", value: "50", type: "P", percentof: "coverageA" },
        { Name: "coverageD", value: "20", type: "P", percentof: "coverageA" },
        { Name: "coverageE", value: "100000", type: "V", percentof: "" },
        { Name: "coverageF", value: "1000", type: "V", percentof: "" },
        { Name: "lossAssessment", value: "1000", type: "V", percentof: "" },
        { Name: "ordinanceOrLaw", value: "10", type: "V", percentof: "" },
        { Name: "increasedCovC", value: "None", type: "V", percentof: "" },
        { Name: "personalInjuryCover", value: "no", type: "V", percentof: "" },
        {
          Name: "specialComputerCoverage",
          value: "no",
          type: "V",
          percentof: ""
        },
        {
          Name: "replacmentCostContents",
          value: "no",
          type: "V",
          percentof: ""
        },
        {
          Name: "specialPersonalPropertyCoverage",
          value: "no",
          type: "V",
          percentof: ""
        },
        { Name: "waterBackup", value: "0", type: "V", percentof: "" },
        {
          Name: "extendedReplacementCost",
          value: "no",
          type: "V",
          percentof: ""
        },
        {
          Name: "identityFraudExp",
          value: "Excluded",
          type: "V",
          percentof: ""
        },
        {
          Name: "mechanicalBreakDownCoverage",
          value: "no",
          type: "V",
          percentof: ""
        },
        { Name: "allOtherPerils", value: "1000", type: "V", percentof: "" },
        {
          Name: "smokeDeductible",
          value: "Excluded",
          type: "V",
          percentof: ""
        },
        {
          Name: "theftDeductible",
          value: "Excluded",
          type: "V",
          percentof: ""
        },
        {
          Name: "waterDeductible",
          value: "Excluded",
          type: "V",
          percentof: ""
        },
        { Name: "windDeductible", value: "Excluded", type: "V", percentof: "" }
      ]
    },
    {
      Occupancy: "Secondary",
      Coverages: [
        { Name: "coverageB", value: "10", type: "P", percentof: "coverageA" },
        { Name: "coverageC", value: "50", type: "P", percentof: "coverageA" },
        { Name: "coverageD", value: "20", type: "P", percentof: "coverageA" },
        { Name: "coverageE", value: "100000", type: "V", percentof: "" },
        { Name: "coverageF", value: "1000", type: "V", percentof: "" },
        { Name: "lossAssessment", value: "1000", type: "V", percentof: "" },
        { Name: "ordinanceOrLaw", value: "10", type: "V", percentof: "" },
        { Name: "increasedCovC", value: "None", type: "V", percentof: "" },
        { Name: "personalInjuryCover", value: "no", type: "V", percentof: "" },
        {
          Name: "specialComputerCoverage",
          value: "no",
          type: "V",
          percentof: ""
        },
        {
          Name: "replacmentCostContents",
          value: "no",
          type: "V",
          percentof: ""
        },
        {
          Name: "specialPersonalPropertyCoverage",
          value: "no",
          type: "V",
          percentof: ""
        },
        { Name: "waterBackup", value: "0", type: "V", percentof: "" },
        {
          Name: "extendedReplacementCost",
          value: "no",
          type: "V",
          percentof: ""
        },
        {
          Name: "identityFraudExp",
          value: "Excluded",
          type: "V",
          percentof: ""
        },
        {
          Name: "mechanicalBreakDownCoverage",
          value: "no",
          type: "V",
          percentof: ""
        },
        { Name: "perilsDeductible", value: "1000", type: "V", percentof: "" },
        {
          Name: "smokeDeductible",
          value: "Excluded",
          type: "V",
          percentof: ""
        },
        {
          Name: "theftDeductible",
          value: "Excluded",
          type: "V",
          percentof: ""
        },
        {
          Name: "waterDeductible",
          value: "Excluded",
          type: "V",
          percentof: ""
        },
        { Name: "windDeductible", value: "Excluded", type: "V", percentof: "" }
      ]
    },
    {
      Occupancy: "Rental",
      Coverages: [
        { Name: "coverageB", value: "10", type: "P", percentof: "coverageA" },
        { Name: "coverageC", value: "50", type: "P", percentof: "coverageA" },
        { Name: "coverageD", value: "20", type: "P", percentof: "coverageA" },
        { Name: "coverageE", value: "100000", type: "V", percentof: "" },
        { Name: "coverageF", value: "1000", type: "V", percentof: "" },
        { Name: "lossAssessment", value: "1000", type: "V", percentof: "" },
        { Name: "ordinanceOrLaw", value: "10", type: "V", percentof: "" },
        { Name: "increasedCovC", value: "None", type: "V", percentof: "" },
        { Name: "personalInjuryCover", value: "no", type: "V", percentof: "" },
        {
          Name: "specialComputerCoverage",
          value: "no",
          type: "V",
          percentof: ""
        },
        {
          Name: "replacmentCostContents",
          value: "no",
          type: "V",
          percentof: ""
        },
        {
          Name: "specialPersonalPropertyCoverage",
          value: "no",
          type: "V",
          percentof: ""
        },
        { Name: "waterBackup", value: "0", type: "V", percentof: "" },
        {
          Name: "extendedReplacementCost",
          value: "no",
          type: "V",
          percentof: ""
        },
        {
          Name: "identityFraudExp",
          value: "Excluded",
          type: "V",
          percentof: ""
        },
        {
          Name: "mechanicalBreakDownCoverage",
          value: "no",
          type: "V",
          percentof: ""
        },
        { Name: "perilsDeductible", value: "1000", type: "V", percentof: "" },
        {
          Name: "smokeDeductible",
          value: "Excluded",
          type: "V",
          percentof: ""
        },
        {
          Name: "theftDeductible",
          value: "Excluded",
          type: "V",
          percentof: ""
        },
        {
          Name: "waterDeductible",
          value: "Excluded",
          type: "V",
          percentof: ""
        },
        { Name: "windDeductible", value: "Excluded", type: "V", percentof: "" }
      ]
    },
    {
      Occupancy: "Secondary Rental",
      Coverages: [
        { Name: "coverageB", value: "10", type: "P", percentof: "coverageA" },
        { Name: "coverageC", value: "50", type: "P", percentof: "coverageA" },
        { Name: "coverageD", value: "20", type: "P", percentof: "coverageA" },
        { Name: "coverageE", value: "100000", type: "V", percentof: "" },
        { Name: "coverageF", value: "1000", type: "V", percentof: "" },
        { Name: "lossAssessment", value: "1000", type: "V", percentof: "" },
        { Name: "ordinanceOrLaw", value: "10", type: "V", percentof: "" },
        { Name: "increasedCovC", value: "None", type: "V", percentof: "" },
        { Name: "personalInjuryCover", value: "no", type: "V", percentof: "" },
        {
          Name: "specialComputerCoverage",
          value: "no",
          type: "V",
          percentof: ""
        },
        {
          Name: "replacmentCostContents",
          value: "no",
          type: "V",
          percentof: ""
        },
        {
          Name: "specialPersonalPropertyCoverage",
          value: "no",
          type: "V",
          percentof: ""
        },
        { Name: "waterBackup", value: "0", type: "V", percentof: "" },
        {
          Name: "extendedReplacementCost",
          value: "no",
          type: "V",
          percentof: ""
        },
        {
          Name: "identityFraudExp",
          value: "Excluded",
          type: "V",
          percentof: ""
        },
        {
          Name: "mechanicalBreakDownCoverage",
          value: "no",
          type: "V",
          percentof: ""
        },
        { Name: "perilsDeductible", value: "1000", type: "V", percentof: "" },
        {
          Name: "smokeDeductible",
          value: "Excluded",
          type: "V",
          percentof: ""
        },
        {
          Name: "theftDeductible",
          value: "Excluded",
          type: "V",
          percentof: ""
        },
        {
          Name: "waterDeductible",
          value: "Excluded",
          type: "V",
          percentof: ""
        },
        { Name: "windDeductible", value: "Excluded", type: "V", percentof: "" }
      ]
    }
  ],
  LOBListShortHand: [`${process.env.NEXT_PUBLIC_LINE_OF_BUSINESS}`],
  // MastersByContainer: ["underwriter", "agents","agencies"],
  UsersKeys: [
    { storeKey: "underwriter", dataKey: "UnderWriter" },
    { storeKey: "agents", dataKey: "Agent" },
    { storeKey: "agencies", dataKey: "Agencies" }
  ],
  ChartFilters: [
    {
      tabName: "policyanalytics",
      chartName: "transactionTypeWiseCountDoughnut",
      chartFilterName: "TransactionType",
      chartFilterValueField: "keyName"
    },
    {
      tabName: "policyanalytics",
      chartName: "yearwiseCountBar",
      chartFilterName: "transactionyear",
      chartFilterValueField: "keyName"
    },
    {
      tabName: "policyanalytics",
      chartName: "newRenewelWiseCountDoughnut",
      chartFilterName: "ReNewalFlag",
      chartFilterValueField: "keyName"
    },
    {
      tabName: "policyanalytics",
      chartName: "monthwiseCountBar",
      chartFilterName: "TransactionMonth",
      chartFilterValueField: "keyName"
    }
  ],
  FormFactoryTransactionTypes: {
    Application: "NEW",
    Quote: "NEW",
    Endorsement: "END",
    Cancellation: "CAN",
    Reinstate: "REI",
    Policy: "NEW",
    QuoteOffered: "QUOTE"
  },
  GetAnalyticsDataQuery: `
        SELECT c.Id, c.id, c.PolicyNumber, c.PolicyNumber like '%-%' ? 'RB' :'NB' as ReNewalFlag, 
        c.Transaction.Type as SystemType, c.Transaction.Type as SystemTypeDesc, c.Transaction.Type as TransactionType, 
        c.Transaction.Type as TransactionTypeDesc, c.Attributes.Lob as LobCode, 
        c.Transaction.Type = 'Quote' ? '1' :'0' as Quotes, c.Transaction.Type = 'Application' ? '1' :'0' as Applications, 
        c.Transaction.Type = 'Endorsed' ? '1' :'0' as Endorsed, c.Transaction.Type = 'Policy' ? '1' :'0' as Policies, 
        c.Transaction.Type = 'Cancel' ? '1' :'0' as Cancelled, c.Transaction.Type = 'Expire' ? '1' :'0' as Expired, 
        c.Transaction.Type = 'ReInstate' ? '1' :'0' as ReInstate, c.Transaction.Id as TransactionNo, 
        c.Premium.BasicPremium as UnitPremium, c.Premium.BasicPremium as Premium, c.TotalPremium.AnnualPremiumWithFeesAndTaxes as PolicyPremium, 
        1 as NoOfUnits, c.Transaction.Date as TransDate, SUBSTRING(c.Transaction.Date, 0, 4) as transactionyear, 
        SUBSTRING(c.Transaction.Date, INDEX_OF(c.Transaction.Date, \"-\")+1, 2) as TransactionMonth, 
        CONCAT(SUBSTRING(c.Transaction.Date, 0, 4) ,SUBSTRING(c.Transaction.Date, 
        INDEX_OF(c.Transaction.Date, \"-\")+1, 2)) as TransYearMonth, c.Transaction.EffectiveDate as TransactionEffectiveDate, 
        SUBSTRING(c.Transaction.EffectiveDate, 0, 4) as TransEffYear, SUBSTRING(c.Transaction.EffectiveDate, 
        INDEX_OF(c.Transaction.EffectiveDate, \"-\")+1, 2) as TransEffMonth, CONCAT(SUBSTRING(c.Transaction.EffectiveDate, 0, 4) ,
        SUBSTRING(c.Transaction.EffectiveDate, INDEX_OF(c.Transaction.EffectiveDate, \"-\")+1, 2)) as TransEffYearMonth,
        c.QuoteNumber, c.QuoteDate as QuoteCreationDate, SUBSTRING(c.QuoteDate, 0, 4) as QuoteCreationYear, 
        SUBSTRING(c.QuoteDate, INDEX_OF(c.QuoteDate, \"-\")+1, 2) as QuoteCreationMonth, CONCAT(SUBSTRING(c.QuoteDate, 0, 4) ,
        SUBSTRING(c.QuoteDate, INDEX_OF(c.QuoteDate, \"-\")+1, 2)) as QuoteCreationYearMonth, c.Agent.Name as AgentName, c.Agent.Code as AgentCode, 
        c.Agency.Name as AgencyName,c.Agency.Code as AgencyCode, c.UnderWriter.Name as UnderwriterName, c.UnderWriter.Code as UnderwriterCode, c.InsuredAccount.FirstName as InsuredFirstName, 
        c.InsuredAccount.LastName as InsuredLastName, 'None' AS programtype, 'None' AS programtypedesc, 'None' AS inparkoutpark, 
        'None' AS inparkoutparkdesc, 'None' AS ProtectionClassId, 'None' AS ConstructionTypeId, 'None' AS ConstructionTypeDesc, 
        'None' AS BurglarAlarmTypeId, 'None' AS BurglarAlarmTypeDesc, 'None' AS SwimTramp, 'None' AS SwimTrampDesc, c.EffectiveDate as PEffective, 
        c.InsuredAccount.BusinessInfo.BusinessName AS BusinessName, c.ExpirationDate as PExpiration, SUBSTRING(c.ExpirationDate, 0, 4) as ExpirationDateYear, 
        SUBSTRING(c.ExpirationDate, INDEX_OF(c.ExpirationDate, \"-\")+1, 2) as ExpirationDateMonth, CONCAT(SUBSTRING(c.ExpirationDate, 0, 4) ,
        SUBSTRING(c.ExpirationDate, INDEX_OF(c.ExpirationDate, \"-\")+1, 2)) as ExpirationDateYearMonth, c.BindDate as BindDate, 
        SUBSTRING(c.BindDate, 0, 4) as BindDateYear, SUBSTRING(c.BindDate, INDEX_OF(c.BindDate, \"-\")+1, 2) as BindDateMonth, 
        CONCAT(SUBSTRING(c.BindDate, 0, 4) ,SUBSTRING(c.BindDate, INDEX_OF(c.BindDate, \"-\")+1, 2)) as BindDateYearMonth, 
        c.PolicyStatus as PolicyStatus, 'None' AS LatestRecord, c.Attributes.state as State 
        FROM c`,
  MailingImgPath: "/Cogitate/images/mailbox.png",
  PolicyHolder: [
    { key: "First Name", path: "InsuredAccount.FirstName" },
    { key: "Middle Name", path: "InsuredAccount.MiddleName" },
    { key: "Last Name", path: "InsuredAccount.LastName" },
    {
      key: "Insured Email Address",
      path: "InsuredAccount.Communications[1].Value"
    },
    {
      key: "Insured Mobile Number",
      path: "InsuredAccount.Communications[0].Value",
      type: "phone"
    },
    {
      key: "LLC Name",
      path: "Risks.Properties[0].PropertyRiskAttributes.IsLLC"
    },
    {
      key: "Date of Birth",
      path: "InsuredAccount.OtherDetails.DateOfBirth",
      type: "date"
    },
    {
      key: "Marital Status",
      path: "InsuredAccount.OtherDetails.MaritalStatus"
    },
    {
      key: "Any secondary policy holder",
      path: "InsuredAccount.HasJointPolicyHolder",
      type: "boolean"
    }, // required formatting for Yes/No
    {
      key: "Secondary Policy Holder First Name",
      path: "JointPolicyHolders[0].FirstName"
    },
    {
      key: "Secondary Policy Holder Middle Name",
      path: "JointPolicyHolders[0].MiddleName"
    },
    {
      key: "Secondary Policy Holder Last Name",
      path: "JointPolicyHolders[0].LastName"
    },
    {
      key: "Secondary Policy Holder Date of Birth",
      path: "JointPolicyHolders[0].OtherDetails.DateOfBirth",
      type: "date"
    },
    {
      key: "Relation to Primary Policy Holder",
      path: "JointPolicyHolders[0].OtherDetails.RelationToPrimary"
    },
    {
      key: "Secondary Policy Holder Email Address",
      path: "JointPolicyHolders[0].Communications[1].Value"
    },
    {
      key: "Secondary Policy Holder Mobile Number",
      path: "JointPolicyHolders[0].Communications[0].Value",
      type: "phone"
    },
    {
      key: "Insured Property Address",
      path: "Risks.Properties[0].Address.UnFormattedAddress"
    },
    {
      key: "Mailing address",
      path: "InsuredAccount.BusinessInfo.Locations[0].Address.UnFormattedAddress"
    },
    { key: "Policy Term", path: "PolicyTerm.Value" }
  ],
  PropertyCharacteristics: [
    {
      key: "Property Type",
      path: "Risks.Properties[0].PropertyRiskAttributes.PropertyType"
    },
    {
      key: "Occupancy Type",
      path: "Risks.Properties[0].PropertyRiskAttributes.OccupancyType"
    },
    {
      key: "Year Built",
      path: "Risks.Properties[0].PropertyRiskAttributes.YearBuilt"
    },
    {
      key: "Area (Sq. Ft)",
      path: "Risks.Properties[0].PropertyRiskAttributes.SquareFeet",
      type: "number"
    },
    {
      key: "Number of Stories",
      path: "Risks.Properties[0].PropertyRiskAttributes.NoOfStories"
    },
    {
      key: "Bedrooms",
      path: "Risks.Properties[0].PropertyRiskAttributes.NoOfBedrooms"
    },
    {
      key: "Baths",
      path: "Risks.Properties[0].PropertyRiskAttributes.NoOfBaths"
    },
    {
      key: "Home Style",
      path: "Risks.Properties[0].PropertyRiskAttributes.Homestyle"
    },
    {
      key: "Construction Type",
      path: "Risks.Properties[0].PropertyRiskAttributes.ConstructionType"
    },
    {
      key: "Siding Type",
      path: "Risks.Properties[0].PropertyRiskAttributes.SidingType"
    },
    {
      key: "Roof Year",
      path: "Risks.Properties[0].PropertyRiskAttributes.RoofYear"
    },
    {
      key: "Roof Type",
      path: "Risks.Properties[0].PropertyRiskAttributes.RoofType"
    },
    {
      key: "Describe Roof Type",
      path: "Risks.Properties[0].PropertyRiskAttributes.DescribeRoofType"
    },
    {
      key: "Roof Shape",
      path: "Risks.Properties[0].PropertyRiskAttributes.RoofShape"
    },
    {
      key: "Roof Cover",
      path: "Risks.Properties[0].PropertyRiskAttributes.RoofCover"
    },
    {
      key: "Roof Cover Age Classification",
      path: "Risks.Properties[0].PropertyRiskAttributes.RoofCoverAgeClassification"
    },
    {
      key: "Roof Deck Attachment",
      path: "Risks.Properties[0].PropertyRiskAttributes.RoofDeck",
      type: "roofDeckFormatter"
    },
    {
      key: "Opening Protection",
      path: "Risks.Properties[0].PropertyRiskAttributes.OpeningProtection"
    },
    {
      key: "Secondary Water Resistance",
      path: "Risks.Properties[0].PropertyRiskAttributes.SecWaterResistance"
    },
    {
      key: "Roof Slope",
      path: "Risks.Properties[0].PropertyRiskAttributes.RoofSlope",
      type: "roofSlope"
    }, // RF
    {
      key: "Soffit Type",
      path: "Risks.Properties[0].PropertyRiskAttributes.SoffitType"
    },
    {
      key: "Roof to Wall Attachment",
      path: "Risks.Properties[0].PropertyRiskAttributes.RooftoWallAttachment"
    },
    {
      key: "Water Heater Location",
      path: "Risks.Properties[0].PropertyRiskAttributes.WaterHeaterLocation"
    },
    { key: "Latitude", path: "Risks.Properties[0].Address.Lat" },
    { key: "Longitude", path: "Risks.Properties[0].Address.Long" },
    {
      key: "Secured Community",
      path: "Risks.Properties[0].PropertyRiskAttributes.SecuredCommunity"
    },
    {
      key: "Burglar Alarm",
      path: "Risks.Properties[0].PropertyRiskAttributes.BurglarAlarms"
    },
    {
      key: "Fire Alarm",
      path: "Risks.Properties[0].PropertyRiskAttributes.FireAlarm"
    },
    {
      key: "Water Leak Detection Device",
      path: "Risks.Properties[0].PropertyRiskAttributes.WaterLeakDetectionCredit"
    },
    {
      key: "Senior Discount",
      path: "Risks.Properties[0].PropertyRiskAttributes.SeniorDiscount"
    },
    {
      key: "Sprinklers",
      path: "Risks.Properties[0].PropertyRiskAttributes.SprinklerSystem",
      type: "sprinklerSystem"
    },
    {
      key: "Paperless Discount",
      path: "Risks.Properties[0].PropertyRiskAttributes.PaperlessDiscount",
      type: "PaperlessDiscount"
    },
    {
      key: "Home Values",
      path: "Risks.Properties[0].PropertyRiskAttributes.SalesPrice",
      type: "numberWithDollar"
    },
    { key: "Estimated Close Date", path: "EffectiveDate", type: "date" }
  ],
  UnderwritingQuestion: [
    {
      key: "Have you or any applicant experienced any liability loss in the past three years (even if not reported or no payment received) at this or any other location owned or rented by you or any applicant?",
      path: "Underwriting.AnyLiabilityLossInLastThreeYear",
      type: "boolean"
    },
    {
      key: "Is there a swimming pool?",
      path: "Underwriting.IsAnySwimingPool",
      type: "boolean"
    },
    { key: "Pool type", path: "Underwriting.PoolType" },
    {
      key: "Is there a fence at least 4 ft. high with a self-closing, self-latching, and lockable gate or screened enclosure completely surrounding the pool?",
      path: "Underwriting.AnyFenceAtFourFeetHighSurroundingThePool",
      type: "boolean"
    },
    {
      key: "Are there any vicious or exotic animals owned or kept by any applicant on the premises?",
      path: "Underwriting.IsExoticAnimalOrPet",
      type: "boolean"
    },
    {
      key: "During the last five years, has any applicant been convicted of any degree of the crime of fraud, bribery, arson, or any arson-related crime?",
      path: "Underwriting.IsAnyCriminalHistory",
      type: "boolean"
    },
    {
      key: "Will the home be occupied as a residence within 30 days of the policy effective date?",
      path: "Underwriting.WillHomeOccupiedIn30Days",
      type: "boolean"
    },
    {
      key: "Will there be a Family Home Day Care conducted on premises, which is defined as care for at least 2 children from unrelated families, for payment or fee?",
      path: "Underwriting.IsFamilyDayCareConducted",
      type: "boolean"
    },
    {
      key: "Will there be any portion of the residence premises being used for business, including (but not limited to) assisted living, or any other form of in home care?",
      path: "Underwriting.IsAnyBusinessOnPremise",
      type: "boolean"
    }
  ],
  MortgageeDetails: [
    {
      key: "Mortgagee Details",
      path: "Risks.Properties[0].AdditionalParties.MortgageeDetails",
      children: [
        { key: "Name", path: "Name" },
        { key: "Loan No", path: "ReferenceNumber" },
        { key: "Address", path: "Address.UnFormattedAddress" }
      ]
    }
  ],
  AdditionalInsured: [
    {
      key: "Additional Insured",
      path: "Risks.Properties[0].AdditionalParties.AdditionalInsured",
      children: [
        { key: "Name", path: "Name" },
        { key: "Address", path: "Address.UnFormattedAddress" },
        {
          key: "Explain the Insurable Interest in this Location",
          path: "Remark"
        }
      ]
    }
  ],
  AdditionalInterest: [
    {
      key: "Additional Interest",
      path: "Risks.Properties[0].AdditionalParties.AdditionalInterest",
      children: [
        { key: "Name", path: "Name" },
        { key: "Address", path: "Address.UnFormattedAddress" },
        {
          key: "Explain the Insurable Interest in this Location",
          path: "Remark"
        }
      ]
    }
  ],
  PayDetails: [
    { key: "Pay Method", path: "PaymentTerms.PayOption" },
    { key: "Pay Plan", path: "PaymentTerms.PayPlan" }
  ],
  CoverageAndLimits: [
    {
      key: "Dwelling (Coverage A)",
      path: "Risks.Properties[0].Coverages[0].Value",
      type: "numberWithDollar"
    },
    {
      key: "Replacement Cost",
      path: "Risks.Properties[0].PropertyRiskAttributes.ReplacementCost",
      type: "numberWithDollar"
    },
    {
      key: "Other Structures (Coverage B)",
      path: "Risks.Properties[0].Coverages[1].Value",
      type: "percentofCovA"
    },
    {
      key: "Personal Property (Coverage C)",
      path: "Risks.Properties[0].Coverages[2].Value",
      type: "percentofCovA"
    },
    {
      key: "Loss of Use (Coverage D)",
      path: "Risks.Properties[0].Coverages[3].Value",
      type: "percentofCovA"
    },
    {
      key: "Liability (Coverages E)",
      path: "Risks.Properties[0].Coverages[4].Value",
      type: "numberWithDollar"
    },
    {
      key: "Medical Payments (Coverages F)",
      path: "Risks.Properties[0].Coverages[5].Value",
      type: "numberWithDollar"
    },
    {
      key: "All Other Perils",
      path: "Risks.Properties[0].Coverages[19].Value",
      type: "numberWithDollar"
    },
    {
      key: "Hurricane Deductible",
      path: "Risks.Properties[0].Coverages[25].Value",
      type: "hurricaneFormatter"
    },
    {
      key: "Water Deductible",
      path: "Risks.Properties[0].Coverages[22].Value",
      type: "numberWithDollar"
    },
    {
      key: "Sinkhole Deductible",
      path: "Risks.Properties[0].Coverages[26].Value"
    },
    {
      key: "Roof Deductible",
      path: "Risks.Properties[0].Coverages[27].Value",
      type: "roofDed"
    },
    {
      key: "Increased Replacement Cost on Dwelling",
      path: "Risks.Properties[0].Coverages[14].Value"
    },
    {
      key: "Personal Injury Coverage",
      path: "Risks.Properties[0].Coverages[9].Value",
      type: "boolWithPlaceHolder"
    },
    { key: "Identity Theft", path: "Risks.Properties[0].Coverages[15].Value" },
    {
      key: "Ordinance OR Law",
      path: "Risks.Properties[0].Coverages[7].Value",
      type: "percentage"
    },
    {
      key: "Screen Enclosure and/or Carport",
      path: "Risks.Properties[0].Coverages[34].Value"
    },
    {
      key: "Screen Enclosure and/or Carport Limit",
      path: "Risks.Properties[0].Coverages[41].Value",
      type: "numberWithDollar"
    },
    {
      key: "Water Damage Coverage",
      path: "Risks.Properties[0].Coverages[35].Value",
      type: "waterDamage"
    },
    {
      key: "Water Back Up",
      path: "Risks.Properties[0].Coverages[35].SettlementType"
    },
    {
      key: "Sinkhole Coverage",
      path: "Risks.Properties[0].Coverages[28].Value",
      type: "sinkholeCov"
    },
    {
      key: "Limited Fungi Coverage",
      path: "Risks.Properties[0].Coverages[37].Value"
    },
    {
      key: "Loss Assessment",
      path: "Risks.Properties[0].Coverages[6].Value",
      type: "lossAssessment"
    },
    {
      key: "Special Personal Property",
      path: "Risks.Properties[0].Coverages[12].Value"
    },
    {
      key: "Computer Equipment Coverage",
      path: "Risks.Properties[0].Coverages[10].Value",
      type: "computerCov"
    },
    {
      key: "Scheduled Personal Property",
      path: "Risks.Properties[0].isScheduledPersonalProperty"
    },
    {
      key: "Golf Cart Coverage",
      path: "Risks.Properties[0].Coverages[38].Value",
      type: "golfCartCov"
    },
    {
      key: "Dog Liability",
      path: "Risks.Properties[0].Coverages[29].Value",
      type: "dogLiability"
    },
    {
      key: "Number of Dogs",
      path: "Risks.Properties[0].PropertyRiskAttributes.NumberOfDogs"
    }
  ],
  ScheduledPersonalProperty: [
    {
      key: "Scheduled Personal Property",
      path: "Risks.Properties[0].ScheduledPersonalProperty",
      children: [
        { key: "Category", path: "Category" },
        { key: "Description", path: "ItemDescription" },
        { key: "Amount", path: "Amount" },
        {
          key: "Located in Off-Premises Vault",
          path: "LocatedInOffPremisesVault"
        }
      ]
    }
  ],
  GolfCartCoverage: [
    {
      key: "Golf Cart Coverage",
      path: "Risks.Properties[0].GolfCartCoverageDetails",
      children: [
        { key: "Make", path: "Make" },
        { key: "Model", path: "Model" }
      ]
    }
  ],
  DogLiabilityDetails: [
    {
      key: "Dog Liability Details",
      path: "Risks.Properties[0].PropertyRiskAttributes.DogDetails",
      children: [
        { key: "Breed", path: "DogBreed" },
        {
          key: "Past History of Bite or Attack",
          path: "PastHistoryOfBiteOrAttack"
        }
      ]
    }
  ]
}

export { staticItems }
